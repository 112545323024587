import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`The `}<em parentName="p">{`breadcrumb`}</em>{` component (aka, breadcrumb trail) is a secondary navigation pattern that shows hierarchy among content or traces a user’s path. Breadcrumbs enable users to move quickly up to a parent level or previous step. All links in a breadcrumb should be clickable.`}</p>
    <p>{`Breadcrumbs are very effective in products and experiences that have a large amount of content organized in a heirarchy of more than two levels. They are also highly effective in flows that contain multiple steps, operating in a similar way to a progress indicator. Breadcrumbs are always treated as secondary and should never entirely replace the primary navigation.`}</p>
    <p>{`Breadcrumbs’ space-efficient design and high utility make them an easy choice for most designers.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.94565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABDElEQVQoz2NggIGk/3CaJfUfA0Pof4b4iQ8Znj19yrji8DvGRYe/M2488ZZRo/AZA0PCfwbWmGcMgtqhYC1cDtMYsAOYoSQAQZ1wAoYl/WdkTP4nwBD3n1+/6C73q5fP+avX/JLOW/FfrnblezGhlGe8DMn/BZijn/ECXciI24VIXg6a9IMVxHz0/J3GcyB48OjJg6fPXr588/b98cMXnwsxMPxnsG94wUK0l///vwy2+dXLF7pv3779//Dhg/+fPn78/+DB/bdPH94Rgqh5SMjL/+CGAl0GNvD9+w/KQAdeefz48dkXL15e/fDhw/Znz5/zgeTuPn7DSNiFSOD///9kyeHVAKKxYXyGAgDITaKUdKi7vgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "multiple tiers of breadcrumb",
            "title": "multiple tiers of breadcrumb",
            "src": "/static/2087b7471c2cf438c25138058a63e20f/fb070/breadcrumb-usage-1.png",
            "srcSet": ["/static/2087b7471c2cf438c25138058a63e20f/d6747/breadcrumb-usage-1.png 288w", "/static/2087b7471c2cf438c25138058a63e20f/09548/breadcrumb-usage-1.png 576w", "/static/2087b7471c2cf438c25138058a63e20f/fb070/breadcrumb-usage-1.png 1152w", "/static/2087b7471c2cf438c25138058a63e20f/c3e47/breadcrumb-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Breadcrumb type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Location-based`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These illustrate the site’s heirarchy and show the user where they are within that heirarchy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Path-based`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These show the actual steps the user took to get to the current page, rather than reflecting the site’s information architecture. Path-based breadcrumbs are always dynamically generated.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      